@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OpenSansRomanSemiBold.ttf");
  font-family: "Open Sans";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OpenSansRegular.ttf");
  font-family: "Open Sans";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlusJakartaSansRomanBold.ttf");
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlusJakartaSansRomanMedium.ttf");
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
}
