@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Override default styles for form elements */
[type="text"],
input:where(:not([type])),
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  @apply appearance-none bg-gray-50 border border-gray-300 rounded-md px-3 py-2 text-sm;
}

/* Style focus states */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply outline-none ring-2 ring-blue-500 border-blue-500;
}

/* Remove the following block as it's overriding the focus styles */
/* 
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="search"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent;
  border-color: transparent;
}
*/

@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[1140px];
  }
}
body{
  font-family: "Open Sans", sans-serif !important;
}
