body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
}

* {
  box-sizing: border-box;
  line-height: normal;
  margin: unset;
  font-family: "Open Sans", sans-serif !important;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}

input {
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
}

@media  (max-width: 767px) {
  .mobile-device{
    display: none;
  }
}
.page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
    position: relative;
  background: transparent; /* Ensures the background image is not applied here */
  color: white;
  font-family: 'Arial', sans-serif;
  height: 100vh;
}
.phone-button {
  width: 50px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin: 10px; /* Add spacing between images */
  position: absolute;
}

.phone-mockup {
  position: relative;
  display: inline-block;
}

.phone-mockup-image {
  display: block;
}

.phone-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Allows clicks to pass through */
}
.play-button {
  top: 10px;
  right: 10px;
}

.vote-button {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.karaoke-button {
  bottom: 10px;
  right: 10px;
}

.info-container {
  position: relative; /* Ensures the background is not applied to this section */
  background: transparent; /* Ensures the background image is not applied here */
  max-width: 500px;
}

/* Additional styles to center and style the content */
h1, .about-us-button, .coming-soon-button {
  color: #fff; /* Text color */
}
.about-us-button {
  background-color: #585858;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

body p {
  color: #fff;
}

.info-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.info-container p {
  margin-bottom: 20px;
}

.coming-soon-button {
  background-color: #444444;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;
}

.highlight {
  color: #ff7600;
}

.page-override p {
  color: #000 !important;
}

.bg-image{
  background-image: url(../../public/images/Categories-Like.png);
  background-position: center;
    background-size: cover;
    background-color: #000;
    background-repeat: no-repeat;
}
.scrolled{
  background: rgba(0,0,0,0.99) !important;
}
.transition-custom{
  transition: 0.5s ease all;
}
.text-gradient-color{
  background: linear-gradient(180deg, #FFD600 0%, #FF8A00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-color-vs{
  background: linear-gradient(179.87deg, rgba(0, 0, 0, 0) 77.45%, rgba(0, 0, 0, 0.6) 99.89%);
}