body {
  overflow-x: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .chat-menu-container {
    grid-template-columns: repeat(3, 1fr) !important; 
    height: 400px !important; 
    
  }

  .chat-menu-title {
    font-size: 1.25rem !important; 
    margin-bottom: 0.5rem !important; 
  }

  .chat-menu-item {
    padding: 0.5rem !important; 
    height: 40px !important;
   
  }

  .chat-menu-item img {
    height: 30px !important; 
  }
}
@media only screen  and (min-width:1024px) and (max-width:1200px) {
    .grid-change-ipad{
      display: flex !important;
    }
  .change-ipad-pro{
    flex-direction: row !important;
    display: flex !important;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px){
/*  .container-xs {*/
/*    width: 100%;*/
/*    max-width: 100%;*/
/*}*/
}

@media only screen  and (min-width:1401px) and (max-width:1919px) {
 .full-with-details{
    width: 100% !important;
    height: auto !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .large-device-img-1-div{
          grid-template-columns: auto 350px !important;
  }
    .grid-set-large-device{
          grid-template-columns: auto 500px !important;
  }
  .height-auto{
    height: auto !important;
  }
  .full-with-details{
    width: 100% !important;
    height: auto !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .large-device-padding{
      padding-right: 185px !important;
  }
    .padding-right-inner-large{
        padding-right: 150px !important;
    }
}
@media only screen and (min-width: 2501px) and (max-width: 8000px) {
  .full-with-details{
    width: 100% !important;
    height: auto !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
     .large-device-img-1-div{
          grid-template-columns: auto 350px !important;
  }
    .grid-set-large-device{
          grid-template-columns: auto 500px !important;
  }
    .large-device-padding{
      padding-right: 185px !important;
  }
    .padding-right-inner-large{
        padding-right: 150px !important;
    }
}
